<template>
  <v-dialog v-model="dialog" persistent max-width="700">
    <v-card :loading="loading">
      <v-card-title class="headline">
        <v-icon>
          mdi-account
        </v-icon>
        ข้อมูลผู้ใช้งาน
        <span class="subtitle-2"> {{ type == 'add' ? '' : '(แก้ไข)' }}</span>
      </v-card-title>
      <v-container fluid>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-if="type == 'add'"
                label="Username"
                v-model="user.username"
                :rules="reqRule"
                required
              ></v-text-field>
              <v-text-field v-else label="Username" :value="user.username" readonly disabled> </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Password"
                v-model="user.password"
                :rules="type == 'edit' ? [] : reqRule"
                autocomplete="new-password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-select
                label="Roles"
                multiple
                small-chips
                v-model="user.roles"
                :items="roles"
                :rules="[v => v.length > 0 || 'จำเป็นต้องกรอกข้อมูล']"
                required
              ></v-select>
            </v-col>
          </v-row>

          <!-- name -->
          <v-row>
            <v-col cols="12" md="4">
              <v-select label="ยศ" v-model="user.title_id" :items="titles" :rules="reqRule" required></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="ชื่อ" v-model="user.first_name" :rules="reqRule" required></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="นามสกุล" v-model="user.last_name" :rules="reqRule" required></v-text-field>
            </v-col>
          </v-row>
          <!-- info -->
          <v-row>
            <v-col cols="12">
              <v-text-field label="ตำแหน่ง" v-model="user.position" :rules="reqRule" required></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="เลข 13 หลัก"
                v-model="user.cid"
                :rules="cidRule"
                maxlength="13"
                :counter="13"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="เบอร์โทร"
                v-model="user.phone"
                :rules="phoneRule"
                maxlength="10"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- department info -->

          <v-row>
            <v-col cols="12" md="4">
              <v-select
                :items="bureaus"
                @change="bChanged"
                item-text="name_abbr"
                item-value="id"
                v-model="bureauSelected"
                label="เลือก บช."
                class="pr-1"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :items="divisions"
                @change="dChanged"
                item-text="name_abbr"
                item-value="id"
                v-model="divisionSelected"
                label="เลือก บก."
                :disabled="divisions.length == 0"
                clearable
                class="pr-1"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :items="stations"
                v-model="user.station_id"
                item-text="name_abbr"
                item-value="id"
                label="เลือก สถานี"
                required
                :rules="reqRule"
                :disabled="stations.length == 0"
                class="pr-1"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click.native="cancel">ยกเลิก</v-btn>
        <v-btn color="blue darken-3" @click.native="submit" dark>
          <v-icon>
            mdi-content-save
          </v-icon>
          บันทึกข้อมูล
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getRole, title, show, store, update } from '@/api/user'
import { getd, gets } from '@/api/deps'
import { mapGetters } from 'vuex'
export default {
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        if (this.type == 'add') {
          // Add
          store(this.user)
            .then(res => {
              this.$toast.success({
                title: 'Success',
                message: 'เพิ่มข้อมูลเรียบร้อยแล้ว',
              })
              this.$emit('inserted')
              this.cancel()
            })
            .catch(err => {
              this.$toast.error({
                title: 'Error',
                message: 'เกิดข้อผิดพลาดระหว่างทำรายการ',
              })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          // Edit
          update(this.user)
            .then(res => {
              if (res.success) {
                this.$toast.success({
                  title: 'Success',
                  message: 'แก้ไขข้อมูลเรียบร้อยแล้ว',
                })
                this.$emit('inserted')
              }
            })
            .catch(err => {
              this.$toast.error({
                title: 'Error',
                message: 'เกิดข้อผิดพลาดระหว่างทำรายการ',
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
    clear() {
      this.$refs.form.reset()
    },
    async init(id, type) {
      if (type == 'add') {
        this.type = 'add'
        if (this.$refs.form != undefined) this.$refs.form.resetValidation()
        this.user = Object.assign({}, this.user_init)
      } else {
        this.type = 'edit'
        this.user = Object.assign({}, await show(id))
        this.user = { password: '', ...this.user }
        this.bureauSelected = this.user.bid
        this.bChanged()
        this.divisionSelected = this.user.did
        this.dChanged()
      }
    },
    cancel() {
      this.user = Object.assign({}, this.user)
      this.divisions = []
      this.divisionSelected = null
      this.stations = []
      this.bureauSelected = null
      this.$refs.form.resetValidation()
      this.dialog = false
    },
    // utils
    async getTitle() {
      this.titles = await title()
    },
    async getRole() {
      this.roles = await getRole()
    },
    async bChanged() {
      this.divisions = []
      this.stations = []
      this.divisions = await getd(this.bureauSelected)
    },
    async dChanged() {
      this.stations = []
      this.stations = await gets(this.divisionSelected)
    },
  },
  data: () => ({
    valid: true,
    loading: false,
    levels: [],
    titles: [],
    type: 'add',
    roles: [],
    bureauSelected: null,
    divisions: [],
    divisionSelected: null,
    stations: [],
    showPassword: false,
    user_init: {
      id: null,
      station_id: null,
      title_id: null,
      first_name: '',
      last_name: '',
      cid: '',
      phone: '',
      position: '',
      username: '',
      password: '',
      roles: [],
    },
    user: {},
    reqRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
    cidRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล', v => /\d{13}/.test(v) || 'กรุณากรอกเป็นตัวเลขเท่านั้น'],
    phoneRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล', v => /\d{9,10}/.test(v) || 'กรุณากรอกเป็นตัวเลขเท่านั้น'],
  }),
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    ...mapGetters('Appfilter', ['bureaus']),
  },
  created() {
    this.getTitle()
    this.getRole()
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
