<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-toolbar color="indigo darken-3" dark dense flat>
        <v-toolbar-title>
          <v-icon>
            mdi-badge-account
          </v-icon>
          รายชื่อผู้ใช้งาน
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          :items="types"
          label="ค้นหาจาก"
          style="width:40px; !important"
          v-model="type"
          hide-details
          single-line
          dense
          solo-inverted
        ></v-select>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          width="200px"
          single-line
          hide-details
          dense
          solo-inverted
          label="กรอกคำค้นหา"
          clearable
          v-model="keyword"
          @keypress.enter="getList"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-spacer></v-spacer>
        <v-btn color="indigo darken-2" dark small class="mr-1" @click="newOfficer">
          <v-icon small>
            mdi-plus-box
          </v-icon>
          เพิ่มผู้ใช้งาน
        </v-btn>
      </v-card-text>
      <user-table
        :keyword="keyword"
        :type="type"
        ref="otable"
        @row-dbl-click="editOfficer"
        @remove-user="deleteOfficer"
      ></user-table>
    </v-card>

    <!-- dialog -->
    <user-dialog ref="odlg" v-model="userDialog" :type="modify" @inserted="onInserted"></user-dialog>
    <bjp-confirm-dialog ref="confirm" />
  </v-container>
</template>

<script>
import userTable from './components/userTable'
import userDialog from './components/userDialog'
import { destroy } from '@/api/user'
export default {
  methods: {
    getList() {
      this.$refs.otable.getList()
    },
    newOfficer() {
      this.$refs.odlg.init(null, 'add')
      this.userDialog = true
    },
    editOfficer(id) {
      this.$refs.odlg.init(id, 'edit')
      this.userDialog = true
    },
    async deleteOfficer(id) {
      let dlg = await this.$refs.confirm.open('ลบรายการ', 'คุณต้องการลบรายการนี้ ?')
      if (dlg) {
        this.loading = true
        destroy(id)
          .then(res => {
            // console.log(res)
            if (res.success) {
              this.$toast.success({
                title: 'info',
                message: 'ทำรายการเรียบร้อยแล้ว',
              })
              this.$refs.otable.getList()
            }
          })
          .catch(err => {
            this.$toast.error({
              message: 'ไม่สามารถทำรายการได้',
              title: 'Error',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onInserted() {
      this.userDialog = false
      this.$refs.otable.getList()
    },
  },
  data() {
    return {
      keyword: '',
      userDialog: false,
      type: 'username',
      types: [
        { text: 'ชื่อผู้ใช้', value: 'username' },
        { text: 'ชื่อ-นามสกุล', value: 'name' },
        { text: 'เลข 13 หลัก', value: 'cid' },
        { text: 'ตำแหน่ง', value: 'position' },
      ],
      modify: 'add',
      loading: false,
    }
  },
  components: {
    userTable,
    userDialog,
  },
}
</script>

<style></style>
